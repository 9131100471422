module controllers {
    export module costing {
        interface ICostingScope extends ng.IScope {
            entityId: number,
            costModelType: number,
            ngModel: interfaces.costing.ICostingModel,
            loadViewDetails(): Promise<ng.resource.IResourceArray<interfaces.costing.ICostingModel>>,
            recost(): void
        }

        export class costingCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', 'costModelService', 'classificationValueService',
                'bsLoadingOverlayService', "$uibModal"];

            public exchangeRateTypeList: Array<interfaces.applicationcore.IClassificationValue>;

            constructor(private $scope: ICostingScope, private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private costModelService: interfaces.costing.ICostModelService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService, private bsLoadingOverlayService,
                private $uibModal: angular.ui.bootstrap.IModalService) {

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'po.update'
                },
                    () => {
                        return this.loadExchangeRates();
                    });
            }

            loadCostModels() {
                var defer = this.$q.defer();

                if (this.$scope.entityId && this.$scope.costModelType) {
                    this.costModelService.getDropdownList().query({ ownerEntityId: this.$scope.entityId, typeId: this.$scope.costModelType }, (resultList) => {
                        defer.resolve(resultList);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });

                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            loadExchangeRates() {
                var search = {
                    classificationId: 43
                };

                this.classificationValueService.GetClassificationValueByClassification().query(search, (list: Array<interfaces.applicationcore.IClassificationValue>) => {
                    this.exchangeRateTypeList = list;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });
            }

            viewDetails() {
                if (!this.$scope.ngModel.HasCosting) {
                    this.generalService.displayMessageBox("View Costing Detail", "No costings to display. Please recost and try again.");
                } else if (angular.isDefined(this.$scope.loadViewDetails)) {                    
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'po.update'
                    },
                        () => {
                            return this.$scope.loadViewDetails().then((data) => {
                                this.openViewDetails(data);
                            });
                        });
                }
            }

            openViewDetails(costingDetailList: ng.resource.IResourceArray<interfaces.costing.ICostingModel>) {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    templateUrl: 'templates/modules/applicationmain/costing/costingDetailModalView.html',
                    controller: 'costingDetailModalCtrl',
                    controllerAs: 'costingDetailModalCtrl',
                    resolve: {
                        items: () => {
                            return {
                                costingModel: this.$scope.ngModel,
                                exchangeRateDescription: _.find(this.exchangeRateTypeList, (o) => { return o.Id == this.$scope.ngModel.ExchangeRateTypeId }).Description,
                                costingDetailList: costingDetailList
                            };
                        }
                    }
                });
            }

            recost() {
                if (angular.isDefined(this.$scope.recost)) {
                    this.generalService.displayConfirmationBox("Confirm", "This will save and recost, are you sure?").then((result) => {
                        if (result) {
                            this.$scope.recost();
                        }
                    });
                }
            }
        }

        angular.module("app").controller("costingCtrl", controllers.costing.costingCtrl);
    }
}